import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`Open Workout 17.2 has been announced.  If you are competing in the
Open please view the standards video in order to know what to expect
when being judged.  17.2 will be Saturday’s class wod but if you are not
able to make it and you are an unlimited member we will do our best to
judge you today during class if possible.  We will have additional times
outside of class time on Saturday to judge any open gym members as
well.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      